import { AppViewModel } from '../../app/app.vm';
import loginHeader from '../../app/assets/header-login.svg';
import FormSigninAppleButton from '../../app/components/form-signin-apple-button.component';
import FormSubmitButton from '../../app/components/form-submit-button.component';
import FormTextfieldComponent from '../../app/components/form-textfield.component';
import useSignupViewModel from './signup.vm';

interface Props {
    appVm: AppViewModel;
}

export default function SignupView(props: Props) {
    const vm = useSignupViewModel(props.appVm);

    return (
        <div>
            <div className='mx-auto px-4 py-32 max-w-md'>
                <img className='px-16' src={loginHeader} alt='Signup' />

                <FormTextfieldComponent
                    id='email'
                    name='email'
                    autoComplete='email'
                    placeholder='email'
                    type='email'
                    required={true}
                    onChange={(value) => vm.setEmail(value)}
                    className='mt-4'
                />

                <FormTextfieldComponent
                    id='password'
                    name='password'
                    autoComplete=''
                    placeholder='password'
                    type='password'
                    required={true}
                    onChange={(value) => vm.setPassw(value)}
                    className='mt-4'
                />

                <p className='text-red-500 text-xs mt-2'>{vm.errorMessage}</p>

                <FormSubmitButton text='Sign up with email' onClick={vm.signup} className='mt-16' />

                <div className='grid place-items-center mt-2'>
                    <p className='text-font-normal text-base'>or</p>
                </div>

                <FormSigninAppleButton className='mt-2' />
            </div>
        </div>
    );
}
