import DiscoverView from '../../memos/views/discover.view';
import MemosView from '../../memos/views/memos.view';
import RequestsView from '../../memos/views/requests.view';
import ProfileView from '../../users/views/profile.view';
import { AppViewModel } from '../app.vm';
import { ReactComponent as RequestsIcon } from '../assets/icons/fi-br-at.svg';
import { ReactComponent as ProfileIcon } from '../assets/icons/fi-br-circle-user.svg';
import { ReactComponent as DiscoverIcon } from '../assets/icons/fi-br-comet.svg';
import { ReactComponent as MemosIcon } from '../assets/icons/fi-br-memo.svg';
import useMainViewModel from './main.vm';
import NotFoundView from './not-found.view';

interface Props {
    appVm: AppViewModel;
}

export default function MainView(props: Props) {
    const vm = useMainViewModel();

    return (
        <div className='flex h-screen'>
            <div className='bg-primary-dark w-16'>
                <button onClick={(_) => vm.setCurrentTab(0)}>
                    <MemosIcon
                        aria-checked={vm.currentTab === 0}
                        className='fill-font-dim aria-checked:fill-font-bright max-w-16 max-h-16 p-5 hover:fill-font-normal'
                    />
                </button>

                <button onClick={(_) => vm.setCurrentTab(1)}>
                    <DiscoverIcon
                        aria-checked={vm.currentTab === 1}
                        className='fill-font-dim aria-checked:fill-font-bright max-w-16 max-h-16 p-5 hover:fill-font-normal'
                    />
                </button>

                <button onClick={(_) => vm.setCurrentTab(2)}>
                    <RequestsIcon
                        aria-checked={vm.currentTab === 2}
                        className='fill-font-dim aria-checked:fill-font-bright max-w-16 max-h-16 p-5 hover:fill-font-normal'
                    />
                </button>

                <button onClick={(_) => vm.setCurrentTab(3)}>
                    <ProfileIcon
                        aria-checked={vm.currentTab === 3}
                        className='fill-font-dim aria-checked:fill-font-bright max-w-16 max-h-16 p-5 hover:fill-font-normal'
                    />
                </button>
            </div>

            {renderTab()}
        </div>
    );

    function renderTab() {
        switch (vm.currentTab) {
            case 0:
                return <MemosView appVm={props.appVm} />;
            case 1:
                return <DiscoverView appVm={props.appVm} />;
            case 2:
                return <RequestsView appVm={props.appVm} />;
            case 3:
                return <ProfileView appVm={props.appVm} />;
            default:
                return <NotFoundView />;
        }
    }
}
