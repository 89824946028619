import { useState } from 'react';

interface MainViewModel {
    currentTab: number;
    setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
}

export default function useMainViewModel(): MainViewModel {
    const [currentTab, setCurrentTab] = useState<number>(0);

    return {
        currentTab,
        setCurrentTab,
    };
}
