import { frequencyNextEffectiveTriggerAt } from '../../app/util/frequency';
import { LocalNotification } from '../../app/util/notificator';

export default interface Memo {
    id: string;
    user_id: string;
    title: string;
    description?: string;
    priority: number;
    status: string;
    visibility: number;
    frequency?: string;
    trigger_at: number;
    updated_at: number;
    created_at: number;
}

export enum MemoPriority {
    High = 3,
    Medium = 2,
    Low = 1,
    None = 0,
}

export function MemoPriorityDisplayName(priority: number): string {
    switch (priority) {
        case 0:
            return 'None';
        case 1:
            return 'Low';
        case 2:
            return 'Medium';
        case 3:
            return 'High';
        default:
            return 'None';
    }
}

export enum MemoStatus {
    Pending = 'pending',
    Completed = 'completed',
    Deleted = 'deleted',
}

export enum MemoVisility {
    Pub = 1,
    Priv = 0,
}

export enum MemoFrequency {
    Hourly = 'hourly',
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly',
}

export function memoNextEffectiveTriggerAt(memo: Memo, currentTimeInMillis: number): number {
    if (!memo.frequency) return memo.trigger_at;
    return frequencyNextEffectiveTriggerAt(memo.frequency, memo.trigger_at, currentTimeInMillis);
}

export function memoToLocalNotification(memo: Memo): LocalNotification {
    return {
        id: memo.id,
        title: memo.description ? memo.title : 'Perroquet says:',
        description: !memo.description ? memo.title : memo.description,
        frequency: memo.frequency,
        triggerAt: memoNextEffectiveTriggerAt(memo, Date.now()),
    };
}

export function memoRandomPlaceholder(): string {
    const placeholders = [
        'drink more water',
        'do laundry in 2 hours',
        'mow the lawn this afternoon',
        'redeem points tomorrow',
        'rate Perroquet',
        'feed parrot',
        'thaw food by noon',
        'water plants in 15 minutes',
        'take out the trash tonight',
        'replace filter at 2:30 pm',
        'clean room on thursday',
    ];

    return placeholders[Math.floor(Math.random() * placeholders.length)];
}
