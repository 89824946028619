export function getEndOfDayTimestamp(): number {
    const currentDate = new Date();
    currentDate.setHours(23, 59, 59, 999);
    return currentDate.getTime();
}

export function getEndOfDayTimestampAfterSevenDays(): number {
    const currentDate = new Date();
    const endDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    endDate.setHours(23, 59, 59, 999);
    return endDate.getTime();
}

/**
 * Converts a millisecond timestamp to a short date string (MM/DD/YYYY)
 * @param timestamp - The millisecond timestamp to convert
 * @returns A short date string in the format MM/DD/YYYY
 */
export function formatShortDate(timestamp: number): string {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
}

/**
 * Converts a millisecond timestamp to a long date string (Month DD, YYYY)
 * @param timestamp - The millisecond timestamp to convert
 * @returns A long date string in the format Month DD, YYYY
 */
export function formatLongDate(timestamp: number): string {
    const date = new Date(timestamp);
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
}

/**
 * Converts a millisecond timestamp to a time string (HH:MM:SS)
 * @param timestamp - The millisecond timestamp to convert
 * @returns A time string in the format HH:MM:SS
 */
export function formatTime(timestamp: number): string {
    const date = new Date(timestamp);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
}

// /**
//  * Converts a millisecond timestamp to a full date and time string (Month DD, YYYY HH:MM:SS)
//  * @param timestamp - The millisecond timestamp to convert
//  * @returns A date and time string in the format Month DD, YYYY HH:MM:SS
//  */
// export function formatDateAndTime(timestamp: number): string {
//     const date = new Date(timestamp);
//     const monthNames = [
//         'January',
//         'February',
//         'March',
//         'April',
//         'May',
//         'June',
//         'July',
//         'August',
//         'September',
//         'October',
//         'November',
//         'December',
//     ];
//     const month = monthNames[date.getMonth()];
//     const day = String(date.getDate()).padStart(2, '0');
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, '0');
//     const minutes = String(date.getMinutes()).padStart(2, '0');
//     const seconds = String(date.getSeconds()).padStart(2, '0');
//     return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
// }

export function formatDateAndTime(dateInMs: number): string {
    const date = new Date(dateInMs);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${month}/${day}/${year} at ${hours}:${minutes}${ampm}`;
}
