import axios, { AxiosError, AxiosResponse } from 'axios';
import ApiError, { apiErrorFromAxiosError } from '../app/models/api-error.model';
import { Result } from '../app/types/result.type';
import SigninAppleDto from './dtos/signin-apple.dto';
import SigninDto from './dtos/signin.dto';
import SignoutDto from './dtos/signout.dto';
import SignupDto from './dtos/signup.dto';
import AccessInfo from './models/access-info.model';

export default class AuthService {
    URL: string;

    constructor(url: string) {
        this.URL = url + '/auth';
    }

    async signup(dto: SignupDto): Promise<Result<AccessInfo, ApiError>> {
        const url = this.URL + '/signup';
        try {
            const res: AxiosResponse<AccessInfo> = await axios.post(url, dto, {
                withCredentials: true,
            });
            return { success: true, value: res.data };
        } catch (e) {
            return { success: false, error: apiErrorFromAxiosError(e as AxiosError) };
        }
    }

    async signin(dto: SigninDto): Promise<Result<AccessInfo, ApiError>> {
        const url = this.URL + '/signin';
        try {
            const res: AxiosResponse<AccessInfo> = await axios.post(url, dto, {
                withCredentials: true,
            });
            return { success: true, value: res.data };
        } catch (e) {
            return { success: false, error: apiErrorFromAxiosError(e as AxiosError) };
        }
    }

    async signinApple(dto: SigninAppleDto): Promise<Result<AccessInfo, ApiError>> {
        const url = this.URL + '/signin/apple';
        try {
            const res: AxiosResponse<AccessInfo> = await axios.post(url, dto, {
                withCredentials: true,
            });
            return { success: true, value: res.data };
        } catch (e) {
            console.error(e);
            return { success: false, error: apiErrorFromAxiosError(e as AxiosError) };
        }
    }

    async refresh(): Promise<Result<AccessInfo, ApiError>> {
        const url = this.URL + '/refresh';
        try {
            const res: AxiosResponse<AccessInfo> = await axios.post(url, null, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });
            return { success: true, value: res.data };
        } catch (e) {
            return { success: false, error: apiErrorFromAxiosError(e as AxiosError) };
        }
    }

    async signout(dto: SignoutDto): Promise<Result<undefined, ApiError>> {
        const url = this.URL + '/signout';
        try {
            await axios.post(url, dto, { withCredentials: true });
            return { success: true, value: undefined };
        } catch (e) {
            return { success: false, error: apiErrorFromAxiosError(e as AxiosError) };
        }
    }
}
