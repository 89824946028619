import logo from '../assets/logo.svg';
import { frequencyNextEffectiveTriggerAt } from './frequency';

export interface LocalNotification {
    id: string;
    title: string;
    description: string;
    frequency?: string;
    triggerAt: number;
}

let timers: { [key: string]: NodeJS.Timeout } = {};

export function notificatorSchedule(notification: LocalNotification) {
    if (timers[notification.id]) {
        notificatorDelete([notification.id]);
    }

    const currentTimestamp = Date.now();
    const delay = notification.triggerAt - currentTimestamp;

    if (delay > 2147483647) return;

    const timeout = setTimeout(() => {
        notificatorShowNotification(notification);
    }, delay);

    timers[notification.id] = timeout;
}

function notificatorShowNotification(notification: LocalNotification) {
    const options: NotificationOptions = {
        body: notification.description,
        icon: logo,
        silent: false,
        dir: 'ltr',
    };

    new Notification(notification.title, options);

    if (notification.frequency) {
        notificatorSchedule({
            id: notification.id,
            title: notification.title,
            description: notification.description,
            frequency: notification.frequency,
            triggerAt: frequencyNextEffectiveTriggerAt(
                notification.frequency,
                notification.triggerAt,
                Date.now(),
            ),
        });
    } else {
        delete timers[notification.id];
    }
}

export function notificatorDelete(ids: string[]) {
    for (const id of ids) {
        const existingTimer = timers[id];
        clearTimeout(existingTimer);
        delete timers[id];
    }
}

export function notificatorDeleteAll() {
    for (const id in Object.values(timers)) {
        clearTimeout(timers[id]);
        delete timers[id];
    }
}
