import Memo from '../../memos/models/memo.model';

export default class Stash {
    private static instance: Stash;
    public static async shared(): Promise<Stash> {
        if (!Stash.instance) {
            Stash.instance = new Stash();
            await Stash.instance.isInitialized;
        }

        return Stash.instance;
    }

    private static DB_NAME = 'defaultdb';
    private static VERSION = 1;

    private isInitialized: Promise<void>;
    private db: IDBDatabase | undefined = undefined;

    private constructor() {
        this.isInitialized = new Promise<void>((resolve) => {
            const request = indexedDB.open(Stash.DB_NAME, Stash.VERSION);

            request.onerror = (event) => {
                console.error(event);
                resolve();
            };
            request.onupgradeneeded = async (event) => {
                console.log('database upgrade needed.');
                const db = (event.target as IDBOpenDBRequest).result;
                this.createMemosStore(db);
            };
            request.onsuccess = async (event) => {
                console.log('database init');
                this.db = (event.target as IDBOpenDBRequest).result;
                resolve();
            };
        });
    }

    async clearStash() {
        if (!this.db) return;

        const transaction = this.db.transaction([Stash.MEMOS_STORE], 'readwrite');
        const store = transaction.objectStore(Stash.MEMOS_STORE);
        const request = store.clear();

        return new Promise<void>((resolve, reject) => {
            request.onsuccess = () => {
                resolve();
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    private static MEMOS_STORE = 'memos';

    private async createMemosStore(db: IDBDatabase) {
        if (!db.objectStoreNames.contains(Stash.MEMOS_STORE)) {
            const memosStore = db.createObjectStore(Stash.MEMOS_STORE, {
                keyPath: 'id',
                autoIncrement: false,
            });

            memosStore.transaction.oncomplete = (event) => {
                console.log('created memos store');
            };
        }
    }

    async insertMemos(memos: Memo[]) {
        if (!this.db) return;

        const transaction = this.db.transaction([Stash.MEMOS_STORE], 'readwrite');
        const store = transaction.objectStore(Stash.MEMOS_STORE);

        const requests: IDBRequest<IDBValidKey>[] = memos.map((memo) => store.put(memo));

        return new Promise<void>((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve();
            };
            transaction.onerror = () => {
                reject(transaction.error);
            };
            requests.forEach((request) => {
                request.onerror = () => {
                    reject(request.error);
                };
            });
        });
    }

    async getMemos(): Promise<Memo[] | undefined> {
        if (!this.db) return undefined;
        console.log('getting memos from db');

        const transaction = this.db.transaction([Stash.MEMOS_STORE], 'readonly');
        const store = transaction.objectStore(Stash.MEMOS_STORE);
        const request = store.getAll();

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve(request.result);
            };
            transaction.onerror = () => {
                reject(transaction.error);
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }

    async deleteMemo(id: string): Promise<void> {
        if (!this.db) return;

        const transaction = this.db.transaction([Stash.MEMOS_STORE], 'readwrite');
        const store = transaction.objectStore(Stash.MEMOS_STORE);
        const request = store.delete(id);

        return new Promise<void>((resolve, reject) => {
            transaction.oncomplete = () => {
                resolve();
            };
            transaction.onerror = () => {
                reject(transaction.error);
            };
            request.onerror = () => {
                reject(request.error);
            };
        });
    }
}
