import { AppViewModel } from '../../app/app.vm';
import { ReactComponent as IconRepeat } from '../../app/assets/icons/fi-br-arrows-repeat.svg';
import { ReactComponent as IconCircleCompleted } from '../../app/assets/icons/fi-br-check-circle.svg';
import { ReactComponent as IconCircleUnfilled } from '../../app/assets/icons/fi-br-circle.svg';
import Config from '../../app/config';
import { notificatorDelete, notificatorSchedule } from '../../app/util/notificator';
import Stash from '../../app/util/stash';
import EditMemoDto from '../dtos/edit-memo.dto';
import MemosService from '../memos.service';
import Memo, { MemoPriority, MemoStatus, memoToLocalNotification } from '../models/memo.model';

interface Props {
    appVm: AppViewModel;
    memo: Memo;
    onAfterClickMarkAsComplete: (memo: Memo) => void;
}

export default function MemoComponent(props: Props) {
    const memo = props.memo;
    const date = new Date(memo.trigger_at);
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    return (
        <div className='rounded-md hover:bg-primary-light p-2 flex items-center'>
            <button disabled={memo.frequency !== undefined} onClick={onClickMarkAsComplete}>
                {memo.frequency ? (
                    <IconRepeat className={`${buttonFillColor()} size-4 hover:fill-font-normal`} />
                ) : memo.status === MemoStatus.Completed ? (
                    <IconCircleCompleted className='fill-font-dim size-4 hover:fill-font-normal' />
                ) : (
                    <IconCircleUnfilled
                        className={`${buttonFillColor()} size-4 hover:fill-font-normal`}
                    />
                )}
            </button>

            <div className='pl-2'>
                <p className='text-font-normal text-sm font-semibold'>{memo.title}</p>

                <span className='mt-1 flex space-x-2'>
                    <p className='text-font-bright text-xs font-semibold bg-primary-dark rounded-md px-2 py-1'>
                        {formattedDate}
                    </p>

                    {memo.frequency && (
                        <p className='text-yellow-500 text-xs font-semibold bg-primary-dark rounded-md px-2 py-1'>
                            {memo.frequency}
                        </p>
                    )}
                </span>
            </div>
        </div>
    );

    function buttonFillColor(): string {
        switch (memo.priority) {
            case MemoPriority.High:
                return 'fill-red-500';
            case MemoPriority.Medium:
                return 'fill-yellow-500';
            case MemoPriority.Low:
                return 'fill-blue-500';
            default:
                return 'fill-font-dim';
        }
    }

    async function onClickMarkAsComplete() {
        const newStatus =
            memo.status === MemoStatus.Pending ? MemoStatus.Completed : MemoStatus.Pending;
        const currentTimeInMillis = Date.now();

        const tempMemo: Memo = {
            id: memo.id,
            user_id: memo.user_id,
            title: memo.title,
            description: memo.description,
            priority: memo.priority,
            status: newStatus,
            visibility: memo.visibility,
            frequency: memo.frequency,
            trigger_at: memo.trigger_at,
            updated_at: currentTimeInMillis,
            created_at: memo.created_at,
        };

        await (await Stash.shared()).insertMemos([tempMemo]);

        if (newStatus === MemoStatus.Completed) {
            notificatorDelete([memo.id]);
        } else if (newStatus === MemoStatus.Pending) {
            notificatorSchedule(memoToLocalNotification(memo));
        }

        props.onAfterClickMarkAsComplete(tempMemo);

        const dto: EditMemoDto = {
            status: newStatus,
        };

        const accessToken = await props.appVm.accessToken();
        if (!accessToken) return;

        const result = await new MemosService(Config.BACKEND_URL).editMemo(
            memo.id,
            dto,
            accessToken,
        );

        switch (result.success) {
            case true:
                console.log('success');
                return;
            case false:
                console.error(result.error);
                return;
        }
    }
}
