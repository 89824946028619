import axios, { AxiosError, AxiosResponse } from 'axios';
import ApiError, { apiErrorFromAxiosError } from '../app/models/api-error.model';
import { Result } from '../app/types/result.type';
import EditDeviceDto from './dtos/edit-device.dto';
import Device from './models/device.model';

export default class DevicesService {
    URL: string;

    constructor(url: string) {
        this.URL = url + '/devices';
    }

    async editDevice(
        id: string,
        dto: EditDeviceDto,
        accessToken: string,
    ): Promise<Result<Device, ApiError>> {
        const url = this.URL + `/${id}`;
        try {
            const res: AxiosResponse<Device> = await axios.patch(url, dto, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            return { success: true, value: res.data };
        } catch (e) {
            return { success: false, error: apiErrorFromAxiosError(e as AxiosError) };
        }
    }
}
