import { jwtDecode } from 'jwt-decode';
import { useState } from 'react';
import AuthService from '../auth/auth.service';
import AccessInfo from '../auth/models/access-info.model';
import AccessTokenClaims from '../auth/models/access-token-claims.model';
import DevicesService from '../devices/devices.service';
import Config from './config';
import AppError from './models/app-error.model';
import { Result } from './types/result.type';
import { notificatorDeleteAll } from './util/notificator';
import { sleep } from './util/promises';
import Stash from './util/stash';

let isInitializing = false;
let isRefreshing = false;

export default function useAppViewModel(
    authService: AuthService = new AuthService(Config.BACKEND_URL),
    devicesService: DevicesService = new DevicesService(Config.BACKEND_URL),
): AppViewModel {
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const [accessInfo, setAccessInfo] = useState<AccessInfo | undefined>();
    const [accessTokenClaims, setAccessTokenClaims] = useState<AccessTokenClaims | undefined>();
    const [isSignedIn, setIsSignedIn] = useState<boolean>(
        localStorage.getItem('is_signed_in') === '1',
    );

    const [_theme, _setTheme] = useState<string>(localStorage.getItem('theme') ?? 'slate');

    async function initialize() {
        if (isInitializing) return;
        isInitializing = true;
        console.log('is initializing app vm');

        document.documentElement.classList.add(_theme);
        document.body.classList.add('bg-primary');

        if (isSignedIn) {
            await refresh();
            console.log('is done refreshing access info');
        }

        if (!('Notification' in window)) {
            console.log('browser does not support desktop notification');
        } else {
            Notification.requestPermission();
        }

        setIsInitialized(true);
        isInitializing = false;
        console.log('is done initializing app vm');
    }

    function onSignin(accessInfo: AccessInfo) {
        console.log('onSignin');

        const accessTokenClaimsResult = decodeAccessTokenClaims(accessInfo.access_token);
        switch (accessTokenClaimsResult.success) {
            case true:
                localStorage.setItem('is_signed_in', '1');
                setAccessInfo(accessInfo);
                setAccessTokenClaims(accessTokenClaimsResult.value);
                setIsSignedIn(true);
                return;
            case false:
                console.error(accessTokenClaimsResult.error.message);
                return;
        }
    }

    function decodeAccessTokenClaims(accessToken: string): Result<AccessTokenClaims, AppError> {
        try {
            const claims = jwtDecode(accessToken) as AccessTokenClaims;
            return { success: true, value: claims };
        } catch (e) {
            return { success: false, error: { message: e as string } };
        }
    }

    async function refresh(): Promise<AccessInfo | undefined> {
        if (isRefreshing) return;
        isRefreshing = true;

        const result = await authService.refresh();
        switch (result.success) {
            case true:
                onRefresh(result.value);
                isRefreshing = false;
                return result.value;
            case false:
                isRefreshing = false;
                return undefined;
        }
    }

    function onRefresh(accessInfo: AccessInfo) {
        console.log('onRefresh');

        const accessTokenClaimsResult = decodeAccessTokenClaims(accessInfo.access_token);
        switch (accessTokenClaimsResult.success) {
            case true:
                setAccessInfo(accessInfo);
                setAccessTokenClaims(accessTokenClaimsResult.value);
                return;
            case false:
                console.error(accessTokenClaimsResult.error.message);
                return;
        }
    }

    async function onSignout() {
        console.log('onSignout');

        setAccessInfo(undefined);
        setAccessTokenClaims(undefined);
        await (await Stash.shared()).clearStash();
        notificatorDeleteAll();
        localStorage.removeItem('is_signed_in');
        setIsSignedIn(false);
    }

    async function accessToken(): Promise<string | undefined> {
        if (!accessInfo || !accessTokenClaims) return undefined;

        const currentTimeInSecs = Date.now() / 1000;
        const isExpired = accessTokenClaims.exp - currentTimeInSecs <= 0;
        if (!isExpired) return accessInfo.access_token;

        if (isRefreshing) {
            await sleep(1000);
            return accessToken();
        }

        const _accessInfo = await refresh();
        return _accessInfo?.access_token;
    }

    function setTheme(theme: string) {
        const htmlElement = document.documentElement;
        htmlElement.classList.remove(_theme);
        htmlElement.classList.add(theme);

        _setTheme(theme);
        localStorage.setItem('theme', theme);
    }

    return {
        isInitialized,
        initialize,

        accessInfo,
        accessTokenClaims,
        isSignedIn,

        theme: _theme,
        setTheme,

        onSignin,
        onSignout,
        accessToken,
    };
}

export interface AppViewModel {
    accessInfo?: AccessInfo;
    accessTokenClaims?: AccessTokenClaims;
    isSignedIn: boolean;

    theme: string;
    setTheme: (theme: string) => void;

    onSignin: (accessInfo: AccessInfo) => void;
    onSignout: () => void;
    accessToken: () => Promise<string | undefined>;

    isInitialized: boolean;
    initialize: () => void;
}
