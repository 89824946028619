/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SigninAppleView from '../auth/views/signin-apple.view';
import SigninView from '../auth/views/signin.view';
import SignupView from '../auth/views/signup.view';
import useAppViewModel from './app.vm';
import LandingView from './views/landing.view';
import MainView from './views/main.view';
import NotFoundView from './views/not-found.view';

export default function App() {
    const vm = useAppViewModel();

    useEffect(() => {
        vm.initialize();
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={renderRoot()} />
                <Route path='/signin' element={<SigninView appVm={vm} />} />
                <Route path='/signin/apple' element={<SigninAppleView appVm={vm} />} />
                <Route path='/signup' element={<SignupView appVm={vm} />} />
                <Route path='*' element={<NotFoundView />} />
            </Routes>
        </BrowserRouter>
    );

    function renderRoot() {
        return vm.isInitialized ? (
            vm.isSignedIn ? (
                <MainView appVm={vm} />
            ) : (
                <LandingView />
            )
        ) : (
            <div />
        );
    }
}
