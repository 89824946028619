interface Props {
    id: string;
    name: string;
    autoComplete: string;
    placeholder: string;
    type: string;
    required: boolean;
    onChange: (value: string) => void;
    className?: string;
}

export default function FormTextfieldComponent(props: Props) {
    const { name, onChange, className, ...rest } = props;

    return (
        <div className={className}>
            <p className='text-font-dim text-xs font-bold'>{name.toUpperCase()}</p>

            <input
                {...rest}
                onChange={(e) => onChange(e.target.value)}
                className='text-font-normal placeholder:text-font-dim w-full bg-primary-dark rounded-md border-0 p-3 mt-1'
            />
        </div>
    );
}
