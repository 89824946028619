import axios, { AxiosError, AxiosResponse } from 'axios';
import ApiError, { apiErrorFromAxiosError } from '../app/models/api-error.model';
import { Result } from '../app/types/result.type';
import CreateMemoDto from './dtos/create-memo.dto';
import EditMemoDto from './dtos/edit-memo.dto';
import GetMemosDto from './dtos/get-memos.dto';
import Memo from './models/memo.model';

export default class MemosService {
    URL: string;

    constructor(url: string) {
        this.URL = url + '/memos';
    }

    async createMemo(dto: CreateMemoDto, accessToken: string): Promise<Result<Memo, ApiError>> {
        const url = this.URL;
        try {
            const res: AxiosResponse<Memo> = await axios.post(url, dto, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            return { success: true, value: res.data };
        } catch (e) {
            return { success: false, error: apiErrorFromAxiosError(e as AxiosError) };
        }
    }

    async getMemos(dto: GetMemosDto, accessToken: string): Promise<Result<Memo[], ApiError>> {
        const url = this.URL;
        try {
            const res: AxiosResponse<Memo[]> = await axios.get(url, {
                headers: { Authorization: `Bearer ${accessToken}` },
                params: dto,
            });
            return { success: true, value: res.data };
        } catch (e) {
            return { success: false, error: apiErrorFromAxiosError(e as AxiosError) };
        }
    }

    async getMemo(id: string, accessToken: string): Promise<Result<Memo, ApiError>> {
        const url = this.URL + `/${id}`;
        try {
            const res: AxiosResponse<Memo> = await axios.get(url, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            return { success: true, value: res.data };
        } catch (e) {
            return { success: false, error: apiErrorFromAxiosError(e as AxiosError) };
        }
    }

    async editMemo(
        id: string,
        dto: EditMemoDto,
        accessToken: string,
    ): Promise<Result<Memo, ApiError>> {
        const url = this.URL + `/${id}`;
        try {
            const res: AxiosResponse<Memo> = await axios.patch(url, dto, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            return { success: true, value: res.data };
        } catch (e) {
            return { success: false, error: apiErrorFromAxiosError(e as AxiosError) };
        }
    }

    async archiveMemo(id: string, accessToken: string): Promise<Result<undefined, ApiError>> {
        const result = await this.editMemo(id, { status: 'archived' }, accessToken);
        switch (result.success) {
            case true:
                return { success: true, value: undefined };
            case false:
                return { success: false, error: result.error };
        }
    }
}
