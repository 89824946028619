import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppViewModel } from '../../app/app.vm';
import Config from '../../app/config';
import AuthService from '../auth.service';
import SigninDto from '../dtos/signin.dto';

export default function useSigninViewModel(
    appVm: AppViewModel,
    authService: AuthService = new AuthService(Config.BACKEND_URL),
): SigninViewModel {
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [passw, setPassw] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    async function signin() {
        setIsLoading(true);

        const dto: SigninDto = { email, password: passw };

        const result = await authService.signin(dto);

        setIsLoading(false);

        switch (result.success) {
            case true:
                appVm.onSignin(result.value);
                navigate('/');
                return;
            case false:
                setErrorMessage(result.error.message);
                return;
        }
    }

    function onClickSignup() {
        navigate('/signup');
    }

    return {
        email,
        setEmail,
        passw,
        setPassw,

        isLoading,
        errorMessage,

        signin,
        onClickSignup,
    };
}

interface SigninViewModel {
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    passw: string;
    setPassw: React.Dispatch<React.SetStateAction<string>>;

    isLoading: boolean;
    errorMessage: string;

    signin: () => Promise<void>;
    onClickSignup: () => void;
}
