/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { AppViewModel } from '../../app/app.vm';
import { ReactComponent as IconChevronDown } from '../../app/assets/icons/fi-br-caret-circle-down.svg';
import { ReactComponent as IconChevronUp } from '../../app/assets/icons/fi-br-caret-circle-up.svg';
import MemoComponent from '../components/memo.component';
import CreateMemoView from './create-memo.view';
import useMemosViewModel from './memos.vm';

interface Props {
    appVm: AppViewModel;
}

export default function MemosView(props: Props) {
    const vm = useMemosViewModel(props.appVm, { sort: 'updated_at,asc' });

    useEffect(() => {
        vm.initialize();
    }, []);

    return (
        <div className='flex flex-col w-screen h-screen'>
            <div className='flex-grow overflow-y-auto p-4'>
                <div className='bg-primary-dark rounded-md p-3 flex justify-between'>
                    <p className='text-font-normal font-bold'>Today</p>

                    <button onClick={vm.toggleIsShowingTodayMemos}>
                        {vm.isShowingTodayMemos ? (
                            <IconChevronUp className='fill-font-dim size-4 my-auto' />
                        ) : (
                            <IconChevronDown className='fill-font-dim size-4' />
                        )}
                    </button>
                </div>
                {vm.isShowingTodayMemos && !vm.todayMemos.length ? (
                    <p className='text-font-dim p-3'>All done here!</p>
                ) : null}
                {vm.isShowingTodayMemos &&
                    vm.todayMemos.map((memo) => (
                        <MemoComponent
                            key={memo.id}
                            appVm={props.appVm}
                            memo={memo}
                            onAfterClickMarkAsComplete={vm.onEditMemo}
                        />
                    ))}

                <div className='bg-primary-dark rounded-md p-3 mt-4 flex justify-between'>
                    <p className='text-font-normal font-bold'>In the next 7 days</p>

                    <button onClick={vm.toggleIsShowingSevenDaysMemos}>
                        {vm.isShowingSevenDaysMemos ? (
                            <IconChevronUp className='fill-font-dim size-4 my-auto' />
                        ) : (
                            <IconChevronDown className='fill-font-dim size-4' />
                        )}
                    </button>
                </div>
                {vm.isShowingSevenDaysMemos && !vm.sevenDaysMemos.length ? (
                    <p className='text-font-dim p-3'>Smooth sailing ahead!</p>
                ) : null}
                {vm.isShowingSevenDaysMemos &&
                    vm.sevenDaysMemos.map((memo) => (
                        <MemoComponent
                            key={memo.id}
                            appVm={props.appVm}
                            memo={memo}
                            onAfterClickMarkAsComplete={vm.onEditMemo}
                        />
                    ))}

                <div className='bg-primary-dark rounded-md p-3 mt-4 flex justify-between'>
                    <p className='text-font-normal font-bold'>Later</p>

                    <button onClick={vm.toggleIsShowingLaterMemos}>
                        {vm.isShowingLaterMemos ? (
                            <IconChevronUp className='fill-font-dim size-4 my-auto' />
                        ) : (
                            <IconChevronDown className='fill-font-dim size-4' />
                        )}
                    </button>
                </div>
                {vm.isShowingLaterMemos && !vm.laterMemos.length ? (
                    <p className='text-font-dim p-3'>Nothing planned!</p>
                ) : null}
                {vm.isShowingLaterMemos &&
                    vm.laterMemos.map((memo) => (
                        <MemoComponent
                            key={memo.id}
                            appVm={props.appVm}
                            memo={memo}
                            onAfterClickMarkAsComplete={vm.onEditMemo}
                        />
                    ))}

                <div className='bg-primary-dark rounded-md p-3 mt-4 flex justify-between'>
                    <p className='text-font-normal font-bold'>Completed</p>

                    <button onClick={vm.toggleIsShowingCompletedMemos}>
                        {vm.isShowingCompletedMemos ? (
                            <IconChevronUp className='fill-font-dim size-4 my-auto' />
                        ) : (
                            <IconChevronDown className='fill-font-dim size-4' />
                        )}
                    </button>
                </div>

                {vm.isShowingCompletedMemos && !vm.completedMemos.length ? (
                    <p className='text-font-dim p-3'>I see you've been busy...</p>
                ) : null}
                {vm.isShowingCompletedMemos &&
                    vm.completedMemos.map((memo) => (
                        <MemoComponent
                            key={memo.id}
                            appVm={props.appVm}
                            memo={memo}
                            onAfterClickMarkAsComplete={vm.onEditMemo}
                        />
                    ))}
            </div>

            <div className='bottom-0 px-4'>
                <CreateMemoView appVm={props.appVm} memosVm={vm} />
            </div>
        </div>
    );
}
