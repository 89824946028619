import { useEffect, useRef } from 'react';
import 'react-calendar/dist/Calendar.css';
import { AppViewModel } from '../../app/app.vm';
import { parseNaturalLanguageDate } from '../../app/util/time-parser';
import MemoDatePickerComponent from '../components/memo-date-picker.component';
import MemoFrequencyComponent from '../components/memo-frequency.component';
import MemoPriorityComponent from '../components/memo-priority.component';
import { memoRandomPlaceholder } from '../models/memo.model';
import useCreateMemoViewModel from './create-memo.vm';
import { MemosViewModel } from './memos.vm';

interface Props {
    appVm: AppViewModel;
    memosVm: MemosViewModel;
}

export default function CreateMemoView(props: Props) {
    const vm = useCreateMemoViewModel(props.appVm, props.memosVm);

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        autoResizeTextarea();
    }, [vm.title]);

    function autoResizeTextarea() {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }

    function onChangeTextarea(event: React.ChangeEvent<HTMLTextAreaElement>) {
        event.preventDefault();

        vm.setTitle(event.target.value);

        if (!event.target.value) {
            return vm.setPlaceholder(memoRandomPlaceholder());
        }

        const parsedDate = parseNaturalLanguageDate(event.target.value);

        if (parsedDate) {
            vm.setTriggerAt(parsedDate);
        } else {
            vm.setTriggerAt(Date.now());
        }
    }

    function onKeyDownTextarea(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key !== 'Enter' || e.shiftKey) return;
        e.preventDefault();
        vm.createMemo();
    }

    return (
        <div className='bg-primary-dark border-t-4 border-primary-light rounded-t-md p-4'>
            <textarea
                ref={textareaRef}
                value={vm.title}
                rows={1}
                placeholder={vm.placeholder}
                onChange={onChangeTextarea}
                onKeyDown={onKeyDownTextarea}
                className='text-font-normal placeholder:text-font-dim bg-transparent focus:outline-none w-full resize-none'
            />

            <div className='flex space-x-4 mt-2'>
                <MemoDatePickerComponent triggerAt={vm.triggerAt} setTriggerAt={vm.setTriggerAt} />

                <MemoFrequencyComponent frequency={vm.frequency} setFrequency={vm.setFrequency} />

                <MemoPriorityComponent priority={vm.priority} setPriority={vm.setPriority} />
            </div>
        </div>
    );
}
