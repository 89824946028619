import AppleLogin from 'react-apple-login';
import { ReactComponent as AppleIcon } from '../assets/brands/fi-brands-apple.svg';

interface Props {
    className?: string;
}

export default function FormSigninAppleButton(_props: Props) {
    return (
        <AppleLogin
            clientId='com.beamcove.Perroquet.Web'
            scope='email'
            responseType='code'
            redirectURI='https://perroquet.beamcove.com/signin/apple'
            render={(props) => (
                <button
                    type='submit'
                    className={`text-black text-base font-semibold w-full bg-white rounded-md p-3 hover:opacity-75 flex items-center justify-center ${_props.className}`}
                    onClick={props.onClick}
                >
                    <AppleIcon className='size-4 mr-2' />
                    <span>Sign in with Apple</span>
                </button>
            )}
        />
    );
}
