interface Props {
    text: string;
    onClick: () => void;
    className?: string;
}

export default function FormSubmitButton(props: Props) {
    return (
        <button
            type='submit'
            className={`text-font-normal text-base font-semibold w-full bg-font-bright rounded-md p-3 hover:opacity-75 ${props.className}`}
            onClick={props.onClick}
        >
            {props.text}
        </button>
    );
}
