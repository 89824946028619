import { useState } from 'react';
import Popup from '../../app/components/popup.component';
import { MemoPriority, MemoPriorityDisplayName } from '../models/memo.model';

interface Props {
    priority: number;
    setPriority: React.Dispatch<React.SetStateAction<number>>;
}

export default function MemoPriorityComponent(props: Props) {
    const [isPresentingPopup, setIsPresentingPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsPresentingPopup(!isPresentingPopup);
    };

    const onSelectPriority = (priority: number) => {
        props.setPriority(priority);
        setIsPresentingPopup(false);
    };

    return (
        <div>
            <button
                onClick={onClickButton}
                className='text-font-bright text-sm font-semibold rounded-md border border-primary-light p-2'
            >
                {props.priority !== 0 ? MemoPriorityDisplayName(props.priority) : 'Priority'}
            </button>

            <Popup
                anchorEl={anchorEl}
                isOpen={isPresentingPopup}
                onClose={() => {
                    setIsPresentingPopup(false);
                }}
            >
                <div className='flex flex-col items-start'>
                    <button onClick={() => onSelectPriority(MemoPriority.High)}>High</button>

                    <button onClick={() => onSelectPriority(MemoPriority.Medium)}>Medium</button>

                    <button onClick={() => onSelectPriority(MemoPriority.Low)}>Low</button>

                    <button onClick={() => onSelectPriority(0)}>None</button>
                </div>
            </Popup>
        </div>
    );
}
