/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { AppViewModel } from '../../app/app.vm';
import Config from '../../app/config';
import { getEndOfDayTimestamp, getEndOfDayTimestampAfterSevenDays } from '../../app/util/date';
import { notificatorDelete, notificatorSchedule } from '../../app/util/notificator';
import Stash from '../../app/util/stash';
import GetMemosDto from '../dtos/get-memos.dto';
import MemosService from '../memos.service';
import Memo, {
    MemoStatus,
    memoNextEffectiveTriggerAt,
    memoToLocalNotification,
} from '../models/memo.model';

const KEY_HIDE_TODAY_MEMOS = 'hide_today_memos';
const KEY_HIDE_SEVENDAYS_MEMOS = 'hide_sevendays_memo';
const KEY_HIDE_LATER_MEMOS = 'hide_later_memos';
const KEY_HIDE_COMPLETED_MEMOS = 'hide_completed_memos';

let isInitializing = false;

let _memos: Memo[] = [];
let cursor: string | undefined = undefined;

let isLoading = false;
let reloadTimer: NodeJS.Timer;

export default function useMemosViewModel(
    appVm: AppViewModel,
    dto: GetMemosDto | undefined = undefined,
    memosService: MemosService = new MemosService(Config.BACKEND_URL),
): MemosViewModel {
    const [todayMemos, setTodayMemos] = useState<Memo[]>([]);
    const [sevenDaysMemos, setSevenDaysMemos] = useState<Memo[]>([]);
    const [laterMemos, setLaterMemos] = useState<Memo[]>([]);
    const [completedMemos, setCompletedMemos] = useState<Memo[]>([]);

    const [userId, setUserId] = useState<string | undefined>(dto?.user_id);
    const [search, setSearch] = useState<string | undefined>(dto?.search);
    const [priority, setPriority] = useState<number | undefined>(dto?.priority);
    const [status, setStatus] = useState<string | undefined>(dto?.status);
    const [visibility, setVisibility] = useState<number | undefined>(dto?.visibility);
    const [sort, setSort] = useState<string | undefined>(dto?.sort);
    // const [cursor, setCursor] = useState<string | undefined>(dto?.cursor);
    const [limit, setLimit] = useState<number | undefined>(dto?.limit);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const [isShowingTodayMemos, setIsShowingTodayMemos] = useState<boolean>(
        localStorage.getItem(KEY_HIDE_TODAY_MEMOS) !== '1',
    );
    const [isShowingSevenDaysMemos, setIsShowingSevenDaysMemos] = useState<boolean>(
        localStorage.getItem(KEY_HIDE_SEVENDAYS_MEMOS) !== '1',
    );
    const [isShowingLaterMemos, setIsShowingLaterMemos] = useState<boolean>(
        localStorage.getItem(KEY_HIDE_LATER_MEMOS) !== '1',
    );
    const [isShowingCompletedMemos, setIsShowingCompletedMemos] = useState<boolean>(
        localStorage.getItem(KEY_HIDE_COMPLETED_MEMOS) !== '1',
    );

    async function initialize() {
        if (isInitializing) return;
        isInitializing = true;
        console.log('is initializing memos vm');

        await restoreCachedMemos();

        load();
        clearInterval(reloadTimer);
        reloadTimer = setInterval(() => load(), 300000);

        isInitializing = false;
        console.log('is done initializing memos vm');
    }

    async function restoreCachedMemos() {
        try {
            const cachedMemos = await (await Stash.shared()).getMemos();
            if (cachedMemos) {
                const sortedCachedMemos = cachedMemos.sort((a, b) => b.updated_at - a.updated_at);
                updateCursor(sortedCachedMemos);
                insertAndOrderMemos(sortedCachedMemos);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function load() {
        if (isLoading) return;
        isLoading = true;

        setErrorMessage('');

        const accessToken = await appVm.accessToken();
        if (!accessToken) {
            isLoading = false;
            return;
        }

        const dto: GetMemosDto = {
            id: undefined,
            user_id: appVm.accessTokenClaims?.id,
            search,
            priority,
            status,
            visibility,
            sort,
            cursor,
            limit,
        };

        console.log(dto);

        const result = await memosService.getMemos(dto, accessToken);

        switch (result.success) {
            case true:
                (await Stash.shared()).insertMemos(result.value);
                updateCursor(result.value);
                insertAndOrderMemos(result.value);
                scheduleMemos(result.value);
                isLoading = false;
                return;
            case false:
                console.error(result.error);
                isLoading = false;
                setErrorMessage(result.error.message);
                return;
        }
    }

    function updateCursor(memos: Memo[]) {
        if (!memos.length) return;

        switch (sort) {
            case 'trigger_at,asc':
                cursor = `${memos[0].trigger_at},${memos[0].id}`;
                return;
            case 'trigger_at,desc':
                cursor = `${memos[memos.length - 1].trigger_at},${memos[memos.length - 1].id}`;
                return;
            case 'updated_at,asc':
                cursor = `${memos[0].updated_at},${memos[0].id}`;
                return;
            case 'updated_at,desc':
                cursor = `${memos[memos.length - 1].updated_at},${memos[memos.length - 1].id}`;
                return;
            case 'created_at,asc':
                cursor = `${memos[0].created_at},${memos[0].id}`;
                return;
            case 'created_at,desc':
                cursor = `${memos[memos.length - 1].created_at},${memos[memos.length - 1].id}`;
                return;
            default:
                cursor = `${memos[0].updated_at},${memos[0].id}`;
                return;
        }
    }

    function insertAndOrderMemos(newMemos: Memo[]) {
        const idSet = new Set<string>(newMemos.map((it) => it.id));

        let array = _memos.filter((it) => !idSet.has(it.id));

        if (sort?.endsWith('asc')) {
            array.unshift(...newMemos);
        } else {
            array.push(...newMemos);
        }

        _memos = array;

        const currentTimestamp = Date.now();
        const endOfDayTimestamp = getEndOfDayTimestamp();
        const sevenDaysTimestamp = getEndOfDayTimestampAfterSevenDays();

        sortedTodayMemos(newMemos, currentTimestamp, endOfDayTimestamp, sevenDaysTimestamp);
        sortedSevenDaysMemos(newMemos, currentTimestamp, endOfDayTimestamp, sevenDaysTimestamp);
        sortedLaterMemos(newMemos, currentTimestamp, sevenDaysTimestamp);
        sortedCompletedMemos(newMemos, currentTimestamp);
    }

    function scheduleMemos(newMemos: Memo[]) {
        const currentTimestamp = Date.now();

        const memoIdsToClear = newMemos
            .filter((memo) => memo.status !== MemoStatus.Pending)
            .map((it) => it.id);
        const memosToSchedule = newMemos.filter((memo) => {
            return (
                memo.status === MemoStatus.Pending &&
                (memo.trigger_at > currentTimestamp || memo.frequency)
            );
        });

        notificatorDelete(memoIdsToClear);

        for (const memo of memosToSchedule) {
            notificatorSchedule(memoToLocalNotification(memo));
        }
    }

    function sortedTodayMemos(
        newMemos: Memo[],
        currentTimestamp: number,
        endOfDayTimestamp: number,
        sevenDaysTimestamp: number,
    ) {
        // const idSet = new Set<string>(newMemos.map((it) => it.id));
        // let array = todayMemos.filter((it) => !idSet.has(it.id));
        // array.push(...newMemos);

        setTodayMemos(
            _memos
                .filter((memo) => {
                    return (
                        memo.status === MemoStatus.Pending &&
                        memoIsWithinToday(
                            memo,
                            currentTimestamp,
                            endOfDayTimestamp,
                            sevenDaysTimestamp,
                        )
                    );
                })
                .sort((a, b) => {
                    if (a.trigger_at === b.trigger_at) {
                        return a.updated_at - b.updated_at;
                    }
                    return a.trigger_at - b.trigger_at;
                }),
        );
    }

    function memoIsWithinToday(
        memo: Memo,
        currentTimestamp: number,
        endOfDayTimestamp: number,
        sevenDaysTimestamp: number,
    ): boolean {
        const triggerAt = memoNextEffectiveTriggerAt(memo, currentTimestamp);
        return triggerAt < endOfDayTimestamp && triggerAt < sevenDaysTimestamp;
    }

    function sortedSevenDaysMemos(
        newMemos: Memo[],
        currentTimestamp: number,
        endOfDayTimestamp: number,
        sevenDaysTimestamp: number,
    ) {
        // const idSet = new Set<string>(newMemos.map((it) => it.id));
        // let array = sevenDaysMemos.filter((it) => !idSet.has(it.id));
        // console.log(array);
        // array.push(...newMemos);

        setSevenDaysMemos(
            _memos
                .filter((memo) => {
                    return (
                        memo.status === MemoStatus.Pending &&
                        memoIsWithinSevenDays(
                            memo,
                            currentTimestamp,
                            endOfDayTimestamp,
                            sevenDaysTimestamp,
                        )
                    );
                })
                .sort((a, b) => {
                    if (a.trigger_at === b.trigger_at) {
                        return a.updated_at - b.updated_at;
                    }
                    return a.trigger_at - b.trigger_at;
                }),
        );
    }

    function memoIsWithinSevenDays(
        memo: Memo,
        currentTimestamp: number,
        endOfDayTimestamp: number,
        sevenDaysTimestamp: number,
    ): boolean {
        const triggerAt = memoNextEffectiveTriggerAt(memo, currentTimestamp);
        return triggerAt > endOfDayTimestamp && triggerAt < sevenDaysTimestamp;
    }

    function sortedLaterMemos(
        newMemos: Memo[],
        currentTimestamp: number,
        sevenDaysTimestamp: number,
    ) {
        // const idSet = new Set<string>(newMemos.map((it) => it.id));
        // let array = laterMemos.filter((it) => !idSet.has(it.id));
        // array.push(...newMemos);

        setLaterMemos(
            _memos
                .filter((memo) => {
                    return (
                        memo.status === MemoStatus.Pending &&
                        memoIsWithinLater(memo, currentTimestamp, sevenDaysTimestamp)
                    );
                })
                .sort((a, b) => {
                    if (a.trigger_at === b.trigger_at) {
                        return a.updated_at - b.updated_at;
                    }
                    return a.trigger_at - b.trigger_at;
                }),
        );
    }

    function memoIsWithinLater(
        memo: Memo,
        currentTimestamp: number,
        sevenDaysTimestamp: number,
    ): boolean {
        const triggerAt = memoNextEffectiveTriggerAt(memo, currentTimestamp);
        return triggerAt > sevenDaysTimestamp;
    }

    function sortedCompletedMemos(newMemos: Memo[], currentTimestamp: number) {
        // const idSet = new Set<string>(newMemos.map((it) => it.id));
        // let array = completedMemos.filter((it) => !idSet.has(it.id));
        // array.push(...newMemos);

        setCompletedMemos(
            _memos
                .filter((memo) => {
                    return (
                        memo.status === MemoStatus.Completed &&
                        memoIsWithinCompleted(memo, currentTimestamp)
                    );
                })
                .sort((a, b) => b.trigger_at - a.trigger_at),
        );
    }

    function memoIsWithinCompleted(memo: Memo, currentTimestamp: number): boolean {
        const triggerAt = memoNextEffectiveTriggerAt(memo, currentTimestamp);
        return triggerAt < currentTimestamp;
    }

    function toggleIsShowingTodayMemos() {
        localStorage.setItem(KEY_HIDE_TODAY_MEMOS, isShowingTodayMemos ? '1' : '0');
        setIsShowingTodayMemos(!isShowingTodayMemos);
    }

    function toggleIsShowingSevenDaysMemos() {
        localStorage.setItem(KEY_HIDE_SEVENDAYS_MEMOS, isShowingSevenDaysMemos ? '1' : '0');
        setIsShowingSevenDaysMemos(!isShowingSevenDaysMemos);
    }

    function toggleIsShowingLaterMemos() {
        localStorage.setItem(KEY_HIDE_LATER_MEMOS, isShowingLaterMemos ? '1' : '0');
        setIsShowingLaterMemos(!isShowingLaterMemos);
    }

    function toggleIsShowingCompletedMemos() {
        localStorage.setItem(KEY_HIDE_COMPLETED_MEMOS, isShowingCompletedMemos ? '1' : '0');
        setIsShowingCompletedMemos(!isShowingCompletedMemos);
    }

    function onCreateMemo(memo: Memo) {
        insertAndOrderMemos([memo]);
    }

    function onEditMemo(memo: Memo) {
        insertAndOrderMemos([memo]);
    }

    function onDeleteMemo(memo: Memo) {
        setTodayMemos(todayMemos.filter((_memo) => _memo.id !== memo.id));
        setSevenDaysMemos(sevenDaysMemos.filter((_memo) => _memo.id !== memo.id));
        setLaterMemos(laterMemos.filter((_memo) => _memo.id !== memo.id));
        setCompletedMemos(completedMemos.filter((_memo) => _memo.id !== memo.id));
    }

    return {
        initialize,

        todayMemos: todayMemos,
        sevenDaysMemos: sevenDaysMemos,
        laterMemos: laterMemos,
        completedMemos: completedMemos,

        errorMessage,

        isShowingTodayMemos,
        toggleIsShowingTodayMemos,
        isShowingSevenDaysMemos,
        toggleIsShowingSevenDaysMemos,
        isShowingLaterMemos,
        toggleIsShowingLaterMemos,
        isShowingCompletedMemos,
        toggleIsShowingCompletedMemos,

        onCreateMemo,
        onEditMemo,
        onDeleteMemo,
    };
}

export interface MemosViewModel {
    initialize: () => void;

    todayMemos: Memo[];
    sevenDaysMemos: Memo[];
    laterMemos: Memo[];
    completedMemos: Memo[];

    errorMessage: string;

    isShowingTodayMemos: boolean;
    toggleIsShowingTodayMemos: () => void;
    isShowingSevenDaysMemos: boolean;
    toggleIsShowingSevenDaysMemos: () => void;
    isShowingLaterMemos: boolean;
    toggleIsShowingLaterMemos: () => void;
    isShowingCompletedMemos: boolean;
    toggleIsShowingCompletedMemos: () => void;

    onCreateMemo: (memo: Memo) => void;
    onEditMemo: (memo: Memo) => void;
    onDeleteMemo: (memo: Memo) => void;
}
