export default function LandingView() {
    return (
        <div>
            <div className='max-w-screen-xl mx-auto px-4 py-32 sm:py-48 lg:py-56'>
                <h1 className='text-font-normal text-4xl sm:text-6xl font-bold'>
                    A mighty companion,
                    <br />
                    ready to chirp your memories
                </h1>

                <p className='text-font-dim text-lg font-normal mt-6'>
                    Perroquet keeps track of reminders and notifies you at the right time
                    <br />
                    Sign up today for a smoother, more organized life
                </p>

                <div className='flex mt-6'>
                    <a
                        href='/signin'
                        className='text-font-normal text-base font-semibold bg-font-bright rounded-md hover:opacity-75 p-3'
                    >
                        Get started
                    </a>
                </div>
            </div>

            <div className='text-center max-w-screen-xl mx-auto px-4 py-32 sm:py-48 lg:py-56'>
                <h1 className='text-font-normal text-2xl sm:text-4xl font-bold'>
                    What people are saying
                </h1>

                <p className='text-font-dim mt-4'>"Wow it's incredible"</p>
            </div>

            <div className='text-center max-w-screen-xl mx-auto px-4 py-32 sm:py-48 lg:py-56'>
                <h1 className='text-font-normal text-2xl sm: text-4xl font-bold'>Download</h1>
            </div>
        </div>
    );
}
