/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppViewModel } from '../../app/app.vm';
import Config from '../../app/config';
import AuthService from '../auth.service';

interface Props {
    appVm: AppViewModel;
}

export default function SigninAppleView(props: Props) {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        async function signin() {
            const searchParams = new URLSearchParams(location.search);
            const code = searchParams.get('code');
            console.log('Code from query:', code);

            if (!code) return navigate('/signin');

            const authService = new AuthService(Config.BACKEND_URL);

            const result = await authService.signinApple({ auth_code: code, client: 'web' });
            switch (result.success) {
                case true:
                    props.appVm.onSignin(result.value);
                    navigate('/');
                    return;
                case false:
                    navigate('/signin');
                    return;
            }
        }

        signin();
    }, []);

    return <div></div>;
}
