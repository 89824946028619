import React, { useEffect, useRef, useState } from 'react';

interface Props {
    anchorEl?: HTMLElement | null;
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

export default function Popup(props: Props) {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const popRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (props.isOpen && popRef.current && !popRef.current.contains(event.target as Node)) {
                props.onClose();
            }
        };

        if (props.isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [props]);

    useEffect(() => {
        if (props.isOpen && props.anchorEl && popRef.current) {
            const { top, left, width, height } = props.anchorEl.getBoundingClientRect();
            const popWidth = popRef.current.offsetWidth;
            const popHeight = popRef.current.offsetHeight;

            let calcLeft = left + width;
            let calcTop = top;

            // Adjust position to avoid screen edges
            if (left + width + popWidth > window.innerWidth) {
                calcLeft = left - popWidth;
            }
            if (top + popHeight > window.innerHeight) {
                calcTop = top - popHeight + height;
            }

            setPosition({ top: calcTop, left: calcLeft });
        }
    }, [props.isOpen, props.anchorEl]);

    return (
        <div
            className={`fixed z-50 ${props.isOpen ? 'block' : 'hidden'}`}
            style={{ top: position.top, left: position.left }}
        >
            <div ref={popRef} className='bg-font-normal rounded-md p-4'>
                {props.children}
            </div>
        </div>
    );
}
