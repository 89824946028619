import { parse } from 'chrono-node';
import { addMilliseconds, isFuture } from 'date-fns';

/**
 * Translates natural language into a date timestamp in milliseconds.
 * @param text The natural language text describing the date.
 * @param referenceDate Optional reference date for relative dates. Defaults to the current date.
 * @returns The date timestamp in milliseconds, or null if parsing fails.
 */
export function parseNaturalLanguageDate(
    text: string,
    referenceDate: Date = new Date(),
): number | null {
    // Attempt to parse the date using chrono-node
    const chronoResult = parse(text, referenceDate, { forwardDate: true });

    if (!chronoResult || chronoResult.length === 0) {
        return null; // Parsing failed
    }

    const parsedDate = chronoResult[0].start.date();

    if (isNaN(parsedDate.getTime())) {
        return null; // Parsing failed
    }

    if (isFuture(parsedDate)) {
        return parsedDate.getTime(); // Return the timestamp
    } else {
        // Date parsed is in the past, attempt to adjust it
        const adjustedDate = addMilliseconds(parsedDate, 24 * 60 * 60 * 1000); // Add 1 day
        return adjustedDate.getTime(); // Return the timestamp
    }
}
