import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppViewModel } from '../../app/app.vm';
import Config from '../../app/config';
import AuthService from '../auth.service';
import SignupDto from '../dtos/signup.dto';

export default function useSignupViewModel(
    appVm: AppViewModel,
    authService: AuthService = new AuthService(Config.BACKEND_URL),
): SigninViewModel {
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [passw, setPassw] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    async function signup() {
        setIsLoading(true);

        const dto: SignupDto = { email, password: passw };

        const result = await authService.signup(dto);

        setIsLoading(false);

        switch (result.success) {
            case true:
                appVm.onSignin(result.value);
                navigate('/');
                return;
            case false:
                setErrorMessage(result.error.message);
                return;
        }
    }

    function onClickTerms() {
        console.log('did click terms');
    }

    return {
        email,
        setEmail,
        passw,
        setPassw,

        isLoading,
        errorMessage,

        signup,
        onClickTerms,
    };
}

interface SigninViewModel {
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    passw: string;
    setPassw: React.Dispatch<React.SetStateAction<string>>;

    isLoading: boolean;
    errorMessage: string;

    signup: () => Promise<void>;
    onClickTerms: () => void;
}
