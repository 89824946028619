import { AppViewModel } from '../../app/app.vm';
import loginHeader from '../../app/assets/header-login.svg';
import FormSigninAppleButton from '../../app/components/form-signin-apple-button.component';
import FormSubmitButton from '../../app/components/form-submit-button.component';
import FormTextfieldComponent from '../../app/components/form-textfield.component';
import useSigninViewModel from './signin.vm';

interface Props {
    appVm: AppViewModel;
}

export default function SigninView(props: Props) {
    const vm = useSigninViewModel(props.appVm);

    return (
        <div>
            <div className='mx-auto px-4 py-32 max-w-md'>
                <img className='px-16' src={loginHeader} alt='Header' />

                <FormTextfieldComponent
                    id='email'
                    name='email'
                    autoComplete='email'
                    placeholder='email'
                    type='email'
                    required={true}
                    onChange={(value) => vm.setEmail(value)}
                    className='mt-4'
                />

                <FormTextfieldComponent
                    id='password'
                    name='password'
                    autoComplete='password'
                    placeholder='password'
                    type='password'
                    required={true}
                    onChange={(value) => vm.setPassw(value)}
                    className='mt-4'
                />

                <div className='grid place-content-end mt-4'>
                    <p className='text-red-500 text-xs mt-2'>{vm.errorMessage}</p>

                    <button type='button' className='text-font-bright text-sm font-semibold'>
                        Forgot password?
                    </button>
                </div>

                <FormSubmitButton text='Sign in with email' onClick={vm.signin} className='mt-16' />

                <p className='text-font-normal text-base mt-2 flex justify-center'>or</p>

                <FormSigninAppleButton className='mt-2' />

                <hr className='bg-font-dim h-px border-0 mt-4'></hr>

                <div className='flex items-center justify-center space-x-2 w-full mt-4'>
                    <p className='text-font-normal font-bold'>Don't have an account?</p>

                    <button
                        type='submit'
                        className='text-font-bright font-bold bg-primary-dark rounded-md p-1.5 hover:opacity-75'
                        onClick={vm.onClickSignup}
                    >
                        Sign up
                    </button>
                </div>
            </div>
        </div>
    );
}
