import { AxiosError } from 'axios';
import JsonObject from './json-object.model';

export default interface ApiError {
    code: number;
    message: string;
}

export function apiErrorFromAxiosError(axiosError: AxiosError): ApiError {
    let data = axiosError.response?.data as JsonObject;
    if (data) {
        let { code, message } = data;
        return { code, message };
    }

    return { code: -1, message: 'An error occurred.' };
}
