import { useNavigate } from 'react-router-dom';

export default function NotFoundView() {
    const navigate = useNavigate();

    return (
        <div>
            <div className='mx-auto px-4 py-32 sm:py-48 lg:py-56 max-w-screen-xl'>
                <p className='font-semibold text-font-bright'>404</p>

                <h1 className='text-font-normal text-3xl sm:text-5xl font-bold mt-4'>
                    Page not found
                </h1>

                <button
                    className='text-font-normal text-sm font-semibold bg-font-bright rounded-md hover:opacity-75 p-3 mt-4'
                    onClick={() => navigate('/')}
                >
                    Go back home
                </button>
            </div>
        </div>
    );
}
