import { useState } from 'react';
import Calendar from 'react-calendar';
import Popup from '../../app/components/popup.component';
import { formatDateAndTime } from '../../app/util/date';

interface Props {
    triggerAt: number;
    setTriggerAt: React.Dispatch<React.SetStateAction<number>>;
}

export default function MemoDatePickerComponent(props: Props) {
    const [isPresentingPopup, setIsPresentingPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsPresentingPopup(!isPresentingPopup);
    };

    const onSelectTriggerAt = (value: any) => {
        const string = value?.toString();
        if (string) {
            const timestamp = Date.parse(string);
            if (timestamp) {
                props.setTriggerAt(timestamp);
                setIsPresentingPopup(false);
            }
        }
    };

    return (
        <div>
            <button onClick={onClickButton}>
                <p className='text-font-bright text-sm font-semibold rounded-md border border-primary-light p-2'>
                    {`on ${formatDateAndTime(props.triggerAt)}`}
                </p>
            </button>

            <Popup
                anchorEl={anchorEl}
                isOpen={isPresentingPopup}
                onClose={() => {
                    setIsPresentingPopup(false);
                }}
            >
                <Calendar
                    onChange={(value) => {
                        onSelectTriggerAt(value);
                    }}
                />
            </Popup>
        </div>
    );
}
