export enum Frequency {
    Hourly = 'hourly',
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly',
    Yearly = 'yearly',
}

export function frequencyNextEffectiveTriggerAt(
    frequency: string,
    fromTime: number,
    currentTimeInMillis: number,
): number {
    const difference = currentTimeInMillis - fromTime;
    if (difference < 0) return fromTime;

    switch (frequency) {
        case Frequency.Hourly:
            return fromTime + 3600000 + difference;
        case Frequency.Daily:
            return fromTime + 86400000 + difference;
        case Frequency.Weekly:
            return fromTime + 604800000 + difference;
        case Frequency.Monthly:
            return fromTime + 2629746000 + difference;
        case Frequency.Yearly:
            return fromTime + 31556952000 + difference;
        default:
            return fromTime;
    }
}
