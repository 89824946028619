import { AppViewModel } from '../../app/app.vm';

interface Props {
    appVm: AppViewModel;
}

export default function ProfileView(props: Props) {
    return (
        <div className='flex flex-col h-screen p-4'>
            <button
                className='text-font-normal bg-primary-dark rounded-md p-2'
                onClick={() => props.appVm.setTheme('light')}
            >
                Light Theme
            </button>

            <button
                className='text-font-normal bg-primary-dark rounded-md p-2'
                onClick={() => props.appVm.setTheme('dark')}
            >
                Dark Theme
            </button>

            <button
                className='text-font-normal bg-primary-dark rounded-md p-2'
                onClick={() => props.appVm.setTheme('slate')}
            >
                Slate Theme
            </button>

            <button
                className='text-font-normal bg-font-bright rounded-md p-2'
                onClick={() => props.appVm.onSignout()}
            >
                Signout
            </button>
        </div>
    );
}
