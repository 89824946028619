/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AppViewModel } from '../../app/app.vm';
import Config from '../../app/config';
import { notificatorSchedule } from '../../app/util/notificator';
import CreateMemoDto from '../dtos/create-memo.dto';
import MemosService from '../memos.service';
import Memo, {
    MemoStatus,
    MemoVisility,
    memoRandomPlaceholder,
    memoToLocalNotification,
} from '../models/memo.model';
import { MemosViewModel } from './memos.vm';

export default function useCreateMemoViewModel(
    appVm: AppViewModel,
    memosVm: MemosViewModel,
    memosService: MemosService = new MemosService(Config.BACKEND_URL),
): CreateMemoViewModel {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [priority, setPriority] = useState(0);
    const [visibility, setVisibility] = useState(MemoVisility.Priv);
    const [frequency, setFrequency] = useState('');
    const [triggerAt, setTriggerAt] = useState(Date.now());

    const [placeholder, setPlaceholder] = useState(memoRandomPlaceholder());
    const [isShowingDatePicker, setIsShowingDatePicker] = useState<boolean>(false);

    async function createMemo() {
        const _id = uuidv4().toLowerCase();
        const _currentTime = Date.now();

        const _title = title;
        const _description = description;
        const _priority = priority;
        const _visibility = visibility;
        const _frequency = frequency;
        const _triggerAt = triggerAt;

        setTitle('');
        setDescription('');
        setPriority(0);
        setVisibility(0);
        setFrequency('');
        setTriggerAt(Date.now());

        const memo: Memo = {
            id: _id,
            user_id: appVm.accessTokenClaims?.id ?? '',
            title: _title,
            description: _description ? _description : undefined,
            priority: _priority,
            status: MemoStatus.Pending,
            visibility: _visibility,
            frequency: _frequency ? _frequency : undefined,
            trigger_at: _triggerAt,
            updated_at: _currentTime,
            created_at: _currentTime,
        };

        // cache memo
        notificatorSchedule(memoToLocalNotification(memo));
        memosVm.onCreateMemo(memo);

        const dto: CreateMemoDto = {
            id: _id,
            title: _title,
            description: _description ? _description : undefined,
            priority: _priority,
            frequency: _frequency ? _frequency : undefined,
            visibility: _visibility,
            trigger_at: _triggerAt,
        };

        const accessToken = await appVm.accessToken();
        if (!accessToken) return;

        const result = await memosService.createMemo(dto, accessToken);
        switch (result.success) {
            case true:
                console.log(result.value);
                return;
            case false:
                console.log(result.error);
                return;
        }
    }

    return {
        title,
        setTitle,
        description,
        setDescription,
        priority,
        setPriority,
        visibility,
        setVisibility,
        frequency,
        setFrequency,
        triggerAt,
        setTriggerAt,

        placeholder,
        setPlaceholder,
        isShowingDatePicker,
        setIsShowingDatePicker,

        createMemo,
    };
}

interface CreateMemoViewModel {
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    description: string;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    priority: number;
    setPriority: React.Dispatch<React.SetStateAction<number>>;
    visibility: number;
    setVisibility: React.Dispatch<React.SetStateAction<number>>;
    frequency: string;
    setFrequency: React.Dispatch<React.SetStateAction<string>>;
    triggerAt: number;
    setTriggerAt: React.Dispatch<React.SetStateAction<number>>;

    placeholder: string;
    setPlaceholder: React.Dispatch<React.SetStateAction<string>>;
    isShowingDatePicker: boolean;
    setIsShowingDatePicker: React.Dispatch<React.SetStateAction<boolean>>;

    createMemo: () => void;
}
