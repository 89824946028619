import { useState } from 'react';
import Popup from '../../app/components/popup.component';
import { stringCapitalized } from '../../app/util/string';
import { MemoFrequency } from '../models/memo.model';

interface Props {
    frequency: string;
    setFrequency: React.Dispatch<React.SetStateAction<string>>;
}

export default function MemoFrequencyComponent(props: Props) {
    const [isPresentingPopup, setIsPresentingPopup] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsPresentingPopup(!isPresentingPopup);
    };

    const onSelectFrequency = (frequency: string) => {
        props.setFrequency(frequency);
        setIsPresentingPopup(false);
    };

    return (
        <div>
            <button
                onClick={onClickButton}
                className='text-font-bright text-sm font-semibold rounded-md border border-primary-light p-2'
            >
                {props.frequency ? stringCapitalized(props.frequency) : 'Repeat'}
            </button>

            <Popup
                anchorEl={anchorEl}
                isOpen={isPresentingPopup}
                onClose={() => {
                    setIsPresentingPopup(false);
                }}
            >
                <div className='flex flex-col items-start'>
                    <button onClick={() => onSelectFrequency(MemoFrequency.Yearly)}>Yearly</button>

                    <button onClick={() => onSelectFrequency(MemoFrequency.Monthly)}>
                        Monthly
                    </button>

                    <button onClick={() => onSelectFrequency(MemoFrequency.Weekly)}>Weekly</button>

                    <button onClick={() => onSelectFrequency(MemoFrequency.Daily)}>Daily</button>

                    <button onClick={() => onSelectFrequency(MemoFrequency.Hourly)}>Hourly</button>

                    <button onClick={() => onSelectFrequency('')}>Once</button>
                </div>
            </Popup>
        </div>
    );
}
